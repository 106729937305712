import {format, distanceInWords, differenceInDays} from 'date-fns'
import ja from 'date-fns/locale/ja'

import React from 'react'
import styled from 'styled-components'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'

import HeadingSpan from './heading-span'
import {colors, font, fontfamily} from '../styles/variables'
import Button from './button'

const Wrapper = styled.article`
  display: block;
  padding: 4rem 0;
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Heading = styled.div`
  max-width: 740px;
  margin: 0 auto;
`

const Title = styled.h1`
  font-size: 2.0rem;
  font-weight: 700;
  font-family: ${fontfamily.jaRounded};
  display: block;
  margin: 0.8rem 2.4rem 3.6rem 2.4rem;
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 2.4rem;
`

const Aside = styled.aside`
  display: block;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const DateCats = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 0 2.4rem;
`

const DateTime = styled.span`
  display: block;
  color: ${colors.lightNavy};
  margin: 0 0.8rem 0 0;
`

const Cats = styled.ul`
  display: flex;
  flex-direction: row;
`
const Cat = styled.li`
  color: ${colors.green};
  margin: 0 0.8rem 0 0;
`

function NewsSingle (props) {
  const {_rawBody, categories, title, mainImage, publishedAt} = props
  return (
    <Wrapper>
      <HeadingSpan title='ニュース' titleEn='NEWS' />
      <Heading>
        <Aside>
          <DateCats>
            {publishedAt && (
              <DateTime>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'YYYY年MM月DD日(dd)', {locale: ja})}
              </DateTime>
            )}
            { categories && (
              <Cats>
                {categories.map(cat => (
                  <Cat key={cat._id} >{cat.title}</Cat>
                ))}
              </Cats>
            )}
          </DateCats>
        </Aside>
        <Title>
          {title}
        </Title>
      </Heading>

      {_rawBody && (
        <Text>
          <PortableText blocks={_rawBody} />
        </Text>
      ) }

      <Button link='/news/' label='ニュース一覧へ' />
    </Wrapper>
  )
}

export default NewsSingle
